'use client';

import { useNotification } from '@/hooks/useNotification';

import * as Notification from '@/components/ui/notification';

const NotificationProvider = () => {
  const { notifications } = useNotification();

  return (
    <Notification.Provider swipeDirection='left' data-oid='uc9ew99'>
      {notifications.map(({ id, title, ...rest }) => {
        return (
          <Notification.Root
            key={id}
            title={title}
            {...rest}
            data-oid='2cornkb'
          />
        );
      })}
      <Notification.Viewport data-oid='1h8_pq3' />
    </Notification.Provider>
  );
};

export { NotificationProvider };
