"use client"

import { useEffect } from "react"
import { useSearchParams } from "next/navigation"
import Cookies from "js-cookie"

export function CouponHandler() {
    const searchParams = useSearchParams()

    useEffect(() => {
        const coupon = searchParams.get("coupon")
        if (coupon) {
            // Set cookie that expires in 24 hours
            Cookies.set("creditcaptain_coupon", coupon, {
                expires: 1, // 1 day
                path: "/",
                secure: process.env.NODE_ENV === "production",
                sameSite: "lax"
            })
        }
    }, [searchParams])

    // This is a utility component that doesn't render anything
    return null
}
