import * as React from 'react';
import * as NotificationPrimitives from '@radix-ui/react-toast';
import { cn } from '@/utils/cn';

import IconCircleCheck from '@public/icons/circle-check.svg';
import IconTriangleExclamation from '@public/icons/triangle-exclamation.svg';
import IconCrossSmall from '@public/icons/cross-small.svg';

const NotificationProvider = NotificationPrimitives.Provider;
const NotificationAction = NotificationPrimitives.Action;

const NotificationViewport = React.forwardRef<
  React.ComponentRef<typeof NotificationPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof NotificationPrimitives.Viewport>
>(({ className, ...rest }, forwardedRef) => (
  <NotificationPrimitives.Viewport
    ref={forwardedRef}
    className={cn(
      'fixed left-0 top-0 z-[100] flex max-h-screen flex-col-reverse p-4 sm:min-w-[372px] sm:max-w-[560px] sm:flex-col sm:p-6',
      className,
    )}
    {...rest}
    data-oid='n::o:3g'
  />
));
NotificationViewport.displayName = 'NotificationViewport';

const colors = {
  error: {
    bg: 'bg-red-light',
    text: 'text-red-dark',
    close: 'text-red-normal',
  },
  success: {
    bg: 'bg-primary-lighter',
    text: 'text-primary-dark',
    close: 'text-primary-dark',
  },
} as const;

type NotificationProps = React.ComponentPropsWithoutRef<
  typeof NotificationPrimitives.Root
> & {
  title: string;
  disableDismiss?: boolean;
  status?: 'error' | 'success';
};

const Notification = React.forwardRef<
  React.ComponentRef<typeof NotificationPrimitives.Root>,
  NotificationProps
>(
  (
    {
      className,
      status = 'error',
      title,
      disableDismiss = false,
      ...rest
    }: NotificationProps,
    forwardedRef,
  ) => {
    let Icon: React.ElementType;

    switch (status) {
      case 'success':
        Icon = IconCircleCheck;
        break;
      case 'error':
        Icon = IconTriangleExclamation;
        break;
      default:
        Icon = IconCircleCheck;
        break;
    }

    return (
      <NotificationPrimitives.Root
        ref={forwardedRef}
        className={cn(
          // open
          'data-[state=open]:animate-in data-[state=closed]:slide-out-to-left-full data-[state=open]:slide-in-from-left-full',
          // close
          'data-[state=closed]:animate-out data-[state=closed]:fade-out-80',
          // swipe
          'data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[swipe=end]:animate-out',
          className,
        )}
        asChild
        {...rest}
        data-oid='85ixmvr'
      >
        <div
          className={cn(
            'flex w-full items-center gap-2.5 rounded-[11px] p-2.5',
            colors[status].bg,
          )}
          data-oid='w69yls6'
        >
          <Icon
            className={cn('size-4 shrink-0', colors[status].text)}
            data-oid='tsu1rlq'
          />

          <NotificationPrimitives.Title
            className={cn('flex-1 text-body-s', colors[status].text)}
            data-oid='6g81gfq'
          >
            {title}
          </NotificationPrimitives.Title>
          {!disableDismiss && (
            <NotificationPrimitives.Close aria-label='Close' data-oid='r1u3e8-'>
              <IconCrossSmall
                className={cn('size-4 opacity-[.32]', colors[status].close)}
                data-oid='xtd9.6l'
              />
            </NotificationPrimitives.Close>
          )}
        </div>
      </NotificationPrimitives.Root>
    );
  },
);
Notification.displayName = 'Notification';

export {
  Notification as Root,
  NotificationProvider as Provider,
  NotificationAction as Action,
  NotificationViewport as Viewport,
  type NotificationProps,
};
